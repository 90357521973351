buttons:
  hsLoginOut: 退出系统
  hsUpdatePassword: 修改密码
  hsfullscreen: 全屏
  hsexitfullscreen: 退出全屏
  hsrefreshRoute: 刷新路由
  hslogin: 登录
  hsadd: 新增
  hsmark: 标记/取消
  hssave: 保存
  hssearch: 搜索
  hsexpendAll: 全部展开
  hscollapseAll: 全部折叠
  hssystemSet: 打开项目配置
  hsdelete: 删除
  hsreload: 重新加载
  hscloseCurrentTab: 关闭当前标签页
  hscloseLeftTabs: 关闭左侧标签页
  hscloseRightTabs: 关闭右侧标签页
  hscloseOtherTabs: 关闭其他标签页
  hscloseAllTabs: 关闭全部标签页
  hscancel: 取消
  hsconfirm: 确认
  nextstep: 下一步
  reupload: 重新上传
  hsback: 返回
menus:
  hshome: 数据看板
  hslogin: 登录
  hsUpdatePassword: 修改密码
  hserror: 错误页面
  hsfourZeroFour: "404"
  hsfourZeroOne: "403"
  hsFive: "500"
  permission: 权限管理
  permissionPage: 页面权限
  permissionButton: 按钮权限
  table: 表格页
  tablePage: 简单表格
  dataBoard: 数据看板
  accountMgr: 账务处理
  reconMgr: 对账管理
  errorMgr: 差错处理
  accountData: 账务管理
  serviceFeeMgr: 服务费管理
  dealDetail: 交易明细
  reconFile: 对账文件
  erroReview: 差错审核
  dataReport: 数据报表
  businessReport: 经营报告
  rankingList: 排行榜
  transactionAnalysis: 交易分析
  currencyAnalysis: 币种分析
  tagMgr: 标签管理
  terminalMgr: 终端管理
  terminalMgrAdd: 新增终端
  terminalMgrEdit: 编辑终端
  terminalMgrDetail: 终端详情
  equipRegister: 设备登记
  reconResultDetails: 对账明细
  reconResultDetail: 对账明细
  reconData: 对账数据
  reconResult: 对账结果
  reconDetail: 对账详情
  errorHandleDetail: 差错处理详情

  dataPreflight: 资料预检
  auditAdmin: 审核管理
  merchantTitle: 商户管理
  merchantInfor: 商户信息
  merchantRecycle: 回收商户标识
  auditDetail: 审核详情
  abnormal: 结算异常处置
  abnormalDetail: 处理
status:
  hsLoad: 加载中...
input:
  please_enter: 请输入
  please_select: 请选择
  support_merchant_name_or_mid_fuzzy_query: 支持商户名称或商户标识模糊查询
  please_select_date_day: 请选择日
  please_select_date_week: 请选择周
  please_select_date_month: 请选择月
page:
  只有admin可看: 只有admin可看
  只有test可看: 只有test可看
  当前角色: 当前角色
  hsSwitchRoles: 切换角色
  查看左侧菜单变化(系统管理)，模拟后台根据不同角色返回对应路由: 查看左侧菜单变化(系统管理)，模拟后台根据不同角色返回对应路由
accountCnter:
  pageTitle: 用户中心
  userTitle: 用户管理
  roleTitle: 角色管理
  add: 新增
  edit: 编辑
acountMgr:
  开始对账: 开始对账
  对账时间: 对账时间
  对账状态: 对账状态
  账期: 账期
  账单ID: 账单ID
  账单周期(季度/月): 账单周期(季度/月)
  账单开始时间: 账单开始时间
  账单结束时间: 账单结束时间
  计费天数: 计费天数
  计费模式: 计费模式
  消费笔数: 消费笔数
  消费金额($): 消费金额($)
  退货笔数: 退货笔数
  退货金额($): 退货金额($)
  退货净额($): 交易净额($)
  服务费($): 服务费($)
  查看明细: 查看明细
  交易明细: 交易明细
  渠道文件名称: 渠道文件名称
  对账日期: 对账日期
  文件大小: 文件大小
  最后更新时间: 最后更新时间
  结束对账: 结束对账
  对账文件导入: 对账文件导入
  查看数据: 查看数据
  重新导入: 重新上传
  导入文件: 上传文件
  对账进度: 对账进度
  渠道名称: 渠道名称
  当前执行批次: 当前执行批次
  对账开始时间: 对账开始时间
  对账结束时间: 对账结束时间
  差错处理状态: 差错处理状态
  查看结果: 查看结果
  重新对账: 重新对账
  差错类型: 差错类型
  笔数: 笔数
  金额: 金额
  对账金额: 对账金额
  网关交易流水: 网关交易流水
  通道流水: 通道流水
  渠道流水: 渠道流水
  总金额: 总金额
  对平金额: 对平金额
  未对平金额: 未对平金额
  未对账金额: 未对账金额
  未对账笔数: 未对账笔数
  总笔数: 总笔数
  对平笔数: 对平笔数
  未对平笔数: 未对平笔数
  对账明细: 对账明细
  渠道ID: 渠道ID
  交易日期: 交易日期
  交易时间: 交易时间
  交易流水号/渠道订单号: 交易流水号
  交易订单号/参考号: 交易订单号
  商户号: 商户标识
  终端号: 终端标识
  商户名称: 商户名称
  交易类型: 交易类型
  交易名称: 交易名称
  交易属性: 交易属性
  付款账户/卡号: 付款账户/卡号
  收单机构: 收单机构
  交易币种: 交易币种
  交易金额: 交易金额
  结算日期: 结算日期
  原交易订单号: 原交易订单号
  对账结果: 对账结果
  对账结果描述: 对账结果描述
  对账笔数: 对账笔数
  差错处理: 差错处理
  结算日: 结算日
  对账日: 对账日
  对账批次号: 对账批次号
  对账环节: 对账环节
  对账日志生成时间: 对账日志生成时间
  上传文件: 上传文件
  选择文件: 选择文件
  提交文件: 提交文件
  提交所有信息: 提交所有信息
  支持扩展名: 支持扩展名
  文件提交中: 文件提交中
  文件提交成功: 文件提交成功
  文件提交失败: 文件提交失败
  信息提交中: 信息提交中
  信息提交失败: 信息提交失败
  信息提交完成: 信息提交完成
  渠道类型: 渠道类型
  下载支付数据对账文件: 下载支付数据对账文件
  网关交易系统对账数据: 网关交易系统对账数据
  支付通道对账数据: 支付通道对账数据
  支付渠道对账数据: 支付渠道对账数据
  通道名称: 通道名称
  通道类型: 通道类型

systemSetting:
  pageTitle: 系统设置

erroReview:
  applicationNo: 申请单号
  subbatchNum: 子批次号
  errorDesc: 差错描述
  handleStatus: 处理状态
  detail: 详情
  lookOver: 查看
  reconciliationDate: 对账日
  channelName: 渠道名称
  executionBatch: 执行批次
  submissionTimesOfErrorApplication: 差错申请提交次数
  reconciliationStatus: 对账状态
  errorType: 差错类型
  channelNum: 渠道号
  switchToMID: 转入商户标识
  transferInMerchantName: 转入商户名称
  switchOutMID: 转出商户标识
  transferOutMerchantName: 转出商户名称
  switchOutTID: 转出终端标识
  switchInTID: 转入终端标识
  merchantOrderNo: 商户订单号
  transactionSerialNumber: 交易流水号
  returnSerialNumber: 退货流水号
  transactionCurrency: 交易币种
  transactionAmount: 交易金额
  transactionType: 交易类型
  tradingTime: 交易时间
  transactionDate: 交易日期
  settlementDate: 结算日期
  sumSeaAppOrderNo: 搜索申请订单号
  searchAndSerialNumber: 搜索交易订单号
  toHandle: 待处理
  errorReconciliation: 差错调账
  businessUnilateralSystem: 业务单边系统
  tradingUnilateralSystem: 交易单边系统
  submitForReview: 提交审核
  all: 全部
  listData: 列表数据
  reject: 驳回
  pass: 通过
  flowInfoOfOriChanel: 原渠道流水信息
  errorReason: 差错原因
  errorHandlingProcess: 差错处理过程
  writeOffReason: 核销原因
  writeOffVoucher: 核销凭证
  wheToParInSubRec: 是否参与后续对账
  view: 预览
  reviewProcess: 审核过程
  auditStatus: 审核状态
  errorReviewStatus: 审核状态
  errorReconciliationStatus: 调账状态
  toReview: "待审核"
  underReview: "审核中"
  reviewed: "已审核"
  notReview: "未审核"
  notHandle: "未处理"
  handlened: "已处理"
  unadjustedAccount: "未调账"
  adjustedAccount: "已调账"
  reviewReject: "已驳回"

merchant:
  merchantOpera: 运营情况
  merchantAdd: 新增商户
  merchantAddChild: 新增子商户
  merchantDetails: 详情
  merchantEdit: 商户编辑

transactionFlow:
  pageTitle: 交易流水
  queryPlaceholder: 搜索商户订单号/设备SN

log:
  pageTitle: 日志管理
  operationLog: 操作日志
  transactionLog: 交易日志

common:
  创建时间: 创建时间
  机构ID: 机构ID
  机构名称: 机构名称
  操作: 操作
  操作人: 操作人
  上传文件: 上传文件
  处理: 处理
  展开: 展开
  收拢: 收拢
  查看: 查看
  搜索: 搜索
  审核: 审核
file:
  fileTitle: 文件管理
  fileExport: 文件导出
  fileImport: 文件导入
# 数据看板
数据看板: 数据看板
今日实时概况: 今日实时概况
(按自然日统计): (按自然日统计)
更新: 更新
选择币种：: 选择币种：
选择币种: 选择币种
商户数量: 商户数量
终端数量: 终端数量
渠道数量: 渠道数量
活跃终端数: 活跃终端数
活跃商户数: 活跃商户数
切换成英文: 切换成英文
切换成中文: 切换成中文
设为默认币种: 设为默认币种
当前默认: 当前默认
消费金额排行（TOP10）: 消费金额排行（TOP10）
消费笔数排行（TOP10）: 消费笔数排行（TOP10）
点击折叠: 点击折叠
回到顶部: 回到顶部
机构名称...: 机构名称...
MerchEnabled: 启用中
MerchDisabled: 禁用
MerchLogout: 已注销
TerEnabled: 启用中
TerDisabled: 禁用
TerLogout: 已注销
机构商户数量: 机构商户数量
交易笔数占比: 交易笔数占比
机构终端数量: 机构终端数量
机构终端占比: 机构终端占比
系统现有渠道总数: 系统现有渠道总数
系统现有商户总数: 系统现有商户总数
所有终端数量: 所有终端数量
在统计时间内有交易产生（消费+退货）的商户数量: 在统计时间内有交易产生（消费+退货）的商户数量
在统计时间内有交易产生（消费+退货）的终端数量: 在统计时间内有交易产生（消费+退货）的终端数量
消费笔数：: 消费笔数：{value}
消费金额：: 消费金额：{value}
机构消费金额占比: 机构消费金额占比
机构消费笔数占比: 机构消费笔数占比
渠道消费笔数: 渠道消费笔数
渠道消费笔数占比: 渠道消费笔数占比
渠道消费金额: 渠道消费金额
渠道消费金额占比: 渠道消费金额占比
在营：: 在营：{total}
启用：: 启用：{total}
0级机构: 0级机构
通道名称: 通道名称
通道ID: 通道ID
#数据报表
日报: 日报
周报: 周报
月报: 月报
展开: 展开
收拢: 收拢
查询: 查询
重置: 重置
导出PDF: 导出PDF
经营概况: 经营概况
现有商户: 现有商户
在营商户: 在营商户
在营率: 在营率
新增商户: 新增商户
注销商户: 注销商户
行业: 行业
经营商户数: 经营商户数
在营率（按商户）: 在营率（按商户）
消费笔数: 消费笔数
排名变化: 排名变化
前10排名: 前10排名
营业净额较上一周: 营业净额较上一周
类别: 类别
占比: 占比
消费单均价: 消费单均价
较上一日: 较上一日
较上一周同天: 较上一周同天
KPI概况: KPI概况
报表数据: 报表数据
消费金额趋势: 消费金额趋势
时间: 时间
消费笔数趋势: 消费笔数趋势
上一日: 上一日
上一周同天: 上一周同天
当日: 当日
消费金额: 消费金额
当周: 当周
当月: 当月
上一周: 上一周
上一月: 上一月
较上一周: 较上一周
较上一月: 较上一月
去年同月: 去年同月
较去年同月: 较去年同月
消费单均价趋势: 消费单均价趋势
更多: 更多
kpi: kpi
当日排名: 当日排名
机构名称（0级）: 机构名称（0级）
导出: 导出
上一日排名: 上一日排名
消费汇总: 消费汇总
消费明细: 消费明细
交易金额: 交易金额
交易笔数: 交易笔数
交易日期: 交易日期
币种: 币种
收入汇总: 收入汇总
收入明细: 收入明细
消费金额（￥）: 消费金额（￥）
金额占比: 金额占比
笔数占比: 笔数占比
日期: 日期
消费金额（: 消费金额（{currency}）
消费笔数（: 消费笔数（{currency}）
消费单均价（: 消费单均价（{currency}）
退货金额（: 退货金额（{currency}）
退货笔数: 退货笔数
暂无数据: 暂无数据
消费金额排行: 消费金额排行
消费笔数排行: 消费笔数排行
后10排名: 后10排名
消费金额（CNY）: 消费金额（CNY）
营业净额较上一月: 营业净额较上一月
请先输入查询条件: 请先输入查询条件
当日消费金额（: 当日消费金额({currency})
当月消费金额（: 当月消费金额({currency})
上一日消费金额（: 上一日消费金额({currency})
上一月消费金额（: 上一月消费金额({currency})
当日消费笔数: 当日消费笔数
上一日消费笔数: 上一日消费笔数
当月消费笔数: 当月消费笔数
上一月消费笔数: 上一月消费笔数
当月消费单均价: 当月消费单均价
上一月消费单均价: 上一月消费单均价
分析报告: 分析报告
请填写所有查询条件: 请填写所有查询条件
请选择日期和币种: 请选择日期和币种
周一: 周一
周二: 周二
周三: 周三
周四: 周四
周五: 周五
周六: 周六
周日: 周日
选择日期: 选择日期
选择时间: 选择时间
选择统计标签: 选择统计标签
日排行: 日排行
选择统计角色: 选择统计角色
周排行: 周排行
月排行: 月排行
较上一日排名变化: 较上一日排名变化
较上一周同天排名变化: 较上一周同天排名变化
消费笔数排行榜: 消费笔数排行榜
消费金额排行榜: 消费金额排行榜
币种分析: 币种分析
交易分析: 交易分析
请先选择机构: 请先选择机构
消费金额占比: 消费金额{label}占比
消费笔数占比: 消费笔数{label}占比
消费单均价占比: 消费单均价{label}占比
该日期未对账: 该日期未对账
# 标签管理
新增标签组: 新增标签组
新增标签: 新增标签
添加标签: 添加标签
标签名称: 标签名称
标签组名称: 标签组名称
搜索标签/标签组: 搜索标签/标签组
删除标签成功: 删除标签成功
删除标签组成功: 删除标签组成功
标签组添加成功: 标签组添加成功
标签添加成功: 标签添加成功
标签名称重复: 标签名称重复
标签组名称重复: 标签组名称重复
当前已选中: 当前已选中
个标签组: 个标签组
是否确认删除？: 是否确认删除？
标签: 标签
# 终端管理
禁用: 禁用
新增: 新增
启用: 启用
禁用成功: 禁用成功
删除成功: 删除成功
启用成功: 启用成功
是否禁用该终端: 是否禁用该终端
是否启用该终端: 是否启用该终端
删除后，终端TID关联的S/N资源将被释放是否继续？: 删除后，终端TID关联的S/N资源将被释放是否继续？
重置密钥: 重置密钥
当前终端管理模式：终端标识 + 商户标识: 当前终端管理模式：商户标识 + 终端标识
当前终端管理模式：终端标识: 当前终端管理模式：终端标识
商户名称: 商户名称
设备S/N信息: 设备S/N信息
无需对S/N校验，非必填: 无需对S/N校验，非必填
需对S/N校验，必填: 需对S/N校验，必填
厂商: 厂商
机型: 机型
终端支付参数回填: 终端支付参数回填
当前商户共计开通: 当前商户共计开通{channels} {channelNum}个渠道，其中仅“{channelsR}”需要回填终端支付参数，请按要求完成填写
转出终端标识参数: 转出终端标识参数
转出商户标识参数: 转出商户标识参数
终端详情: 终端详情
转出终端标识参数：: 转出终端标识参数：
转出商户标识参数：: 转出商户标识参数：
厂商：: 厂商：
机型：: 机型：
搜索商户名称/商户标识/终端标识/SN/厂商/机型: 搜索商户名称/商户标识/终端标识/SN/厂商/机型
重新输入: 重新输入
状态: 状态
请选择状态: 请选择状态
是否重置秘钥: 是否重置秘钥
重置密钥成功: 重置密钥成功
终端信息: 终端信息
该终端已禁用: 该终端已禁用
该终端已启用: 该终端已启用
新增终端成功，即将返回: 新增终端成功，即将返回
编辑终端成功，即将返回: 编辑终端成功，即将返回
# 设备登记
设备入库: 设备入库
设备出库: 设备出库
删除: 删除
编辑: 编辑
编辑设备: 编辑设备
入库方式: 入库方式
出库方式: 出库方式
单个入库: 单个入库
单个出库: 单个出库
批量出库: 批量出库
设备类型: 设备类型
生产厂商: 生产厂商
设备机型: 设备机型
设备SN: 设备SN
出库原因: 出库原因
选择文件: 选择文件
支持扩展名：: 支持扩展名：
下载模板: 下载模板
手动批量入库: 手动批量入库

# 用户管理
解绑: 解绑

system:
  Midrecyclingrules: 商户标识回收规则
  Logoutfrommerchant: 自商户注销
  days: 天
  Since: 起，可回收商户标识

institution:
  institutionTitle: 组织架构
  institutionDetail: 详情
  institutionAdd: 新增机构
  institutionEdit: 机构编辑
channel:
  channelTitle: 渠道配置
  channelDetail: 渠道详情
  channelAdd: 渠道编辑

搜索商户订单号/流水号: 搜索商户订单号/流水号
搜索批次号: 搜索批次号
渠道文件名称: 渠道文件名称

添加角色: 添加角色
请输入机构名称: 请输入机构名称
全部: 全部
列表数据: 列表数据
开始日期: 开始日期
结束日期: 结束日期
交易时间: 交易时间
对账日期: 对账日期
渠道名称: 渠道名称
对账批次: 对账批次
对账开始时间: 对账开始时间
对账结束时间: 对账结束时间
操作: 操作
渠道ID: 渠道ID
交易流水号: 交易流水号
交易订单号: 交易订单号
商户号: 商户标识
终端号: 终端标识
交易类型: 交易类型
交易名称: 交易名称
交易属性: 交易属性
付款账户/卡号: 付款账户/卡号
收单机构: 收单机构
交易币种: 交易币种
结算日期: 结算日期
对账结果: 对账结果
对账结果描述: 对账结果描述
原交易订单号: 原交易订单号
文件大小: 文件大小
最后更新时间: 最后更新时间
操作人: 操作人
机构名称: 机构名称
#差错处理词条
差错审核状态: 差错审核状态
差错处理状态: 差错处理状态
差错调账状态: 差错调账状态
未处理: 未处理
已处理: 已处理
未审核: 未审核
已审核: 已审核
未对账: 未对账
对账中: 对账中
已对平: 已对平
交易要素不匹配: 交易要素不匹配
对方无流水: 对方无流水
正交易: 正交易
负交易: 负交易
审核驳回: 审核驳回
未通过原因: 未通过原因
未通过原因不能为空: 未通过原因不能为空
请填写未通过的原因: 请填写未通过的原因
本地交易记录: 本地交易记录
渠道交易记录: 渠道交易记录
条: 条
审核通过: 审核通过
差错处理进度: 差错处理进度
执行批次: 执行批次
待审核（已处理）: 待审核（已处理）
待调账（已审核）: 待调账（已审核）
已调帐(已完成）: 已调帐(已完成）
搜索订单号/交易流水号: 搜索订单号/交易流水号
请选择交易类型: 请选择交易类型
核销原因: 核销原因
核销凭证: 核销凭证
支持扩展名: 支持扩展名
不参与: 不参与
参与: 参与
差错类型: 差错类型
渠道流水信息: 渠道流水信息
差错处理内容: 差错处理内容
差错详情: 差错详情
处理日志: 处理日志
差错原因: 差错原因
未调账: 未调账
已调帐: 已调帐
确认: 确认
取消: 取消
是否确认删除处理结果？: 是否确认删除处理结果？
差错处理: 差错处理
新增交易系统流水: 新增交易系统流水
修改交易系统流水: 修改交易系统流水
人工核销: 人工核销
处理结果: 处理结果
预览: 预览
是否参与后续对账: 是否参与后续对账
提交处理结果: 提交处理结果
审核结果: 审核结果
审核时间: 审核时间
审核人: 审核人
驳回原因: 驳回原因
调账结果: 调账结果
调账时间: 调账时间
网关缺少流水: 网关缺少流水
网关流水异常: 网关流水异常
上游渠道多单: 上游渠道多单
上游渠道少单: 上游渠道少单
其他: 其他
提交结果成功: 提交结果成功
处理中: 处理中
待审核: 待审核
调账中: 调账中
已完成: 已完成
上游渠道单边: 上游渠道单边
交易系统单边: 交易系统单边
支付要素不一致: 支付要素不一致
未完成: 未完成
已驳回: 已驳回
补录本地流水: 补录本地流水
修改本地流水: 修改本地流水
提交审核: 提交审核
搜索通道文件名称/操作人: 搜索通道文件名称/操作人
请选择上传文件: 请选择上传文件
文件格式不正确，请重新上传！: 文件格式不正确，请重新上传！
欢迎登录！: 欢迎登录！
KlondPay机构平台: KlondPay机构平台
用户名: 用户名
请输入用户名: 请输入用户名
密码: 密码
请输入密码: 请输入密码
请输入验证码: 请输入验证码
登录: 登录
#请选择交易类型: 请选择交易类型
#执行批次: 执行批次

# 商户管理
商户管理: 商户管理
商户标识: 商户标识
层级: 层级
上级商户: 上级商户
上级商户名称: 上级商户名称
商户类型: 行业
结算周期: 结算周期
运营状态: 运营状态
启用状态: 启用状态
推广员: 推广员
代办人: 代办人
注册地址: 注册地址
邮箱地址: 联系邮箱
营业执照编号: 营业执照编号
营业执照有效期: 营业执照有效期
结算账户: 结算账户
SWIF编码: SWIF编码
银行编码: 银行编码
收款行名称: 收款行名称
收款行城市: 收款行城市
地址: 地址
扣率: 扣率
转出渠道: 转出渠道
业务地区: 业务地区
子商户列表: 子商户列表
终端列表: 终端列表
用户列表: 用户列表
联系邮箱: 联系邮箱
产品: 产品
商户标识回收状态: 商户标识回收状态
交易时区: 交易时区
经手人: 经手人
配置支付产品: 配置支付产品
支付参数: 支付参数
营业执照信息: 营业执照信息
结算信息: 结算信息
法人信息: 法人信息
上级机构名称: 上级机构名称
请选择机构: 请选择机构
申请单号: 申请单号
处理状态: 处理状态
搜索机构ID/机构名称: 搜索机构ID/机构名称
搜索商户名称/商户标识: 搜索商户名称/商户标识

绑定角色: 绑定角色
修改数据权限: 修改数据权限
数据权限: 数据权限
姓名: 姓名
所属组织机构: 所属组织机构
角色: 角色
创建人: 创建人
邮箱: 邮箱
手机号: 电话号码
角色名称: 角色名称
搜索用户名/姓名: 搜索用户名/姓名
超级管理员: 超级管理员
管理员: 管理员
机构超级管理员: 机构超级管理员
审核员: 审核员
添加账号: 添加账号
角色描述: 角色描述
设置权限: 设置权限
返回: 返回
日期选择: 日期选择
账号: 账号
操作类型: 操作类型
搜索商户标识/商户订单号/商户名称: 搜索商户标识/商户订单号/商户名称
商户订单号: 商户订单号
正常: 正常
失败: 失败
上游通道单边: 上游通道单边
差错审核: 差错审核
通道: 通道
通道流水信息: 通道流水信息
交易流水号/渠道订单号: 交易流水号/渠道订单号
交易订单号/参考号: 商户订单号/参考号
上游通道多单: 上游通道多单
上游通道少单: 上游通道少单
#--分割线----
未对平: 未对平

请输入S/N: 请输入S/N
请输入数字或字母: 请输入数字或字母
导入成功: 导入成功
未导入: 未导入

修改成功，请重新登录: 修改成功，请重新登录
新增用户: 新增用户
编辑用户: 编辑用户
新增角色: 新增角色
编辑角色: 编辑角色
# 交易流水
网关支付: 网关支付
非网关支付: 非网关支付

共计差错: 共计差错
笔: 笔
对账文件名称: 对账文件名称
数据导入状态: 数据导入状态
数据上传状态: 数据上传状态
描述: 描述

# 补充
机构平台: 机构平台
机构数量: 机构数量
联系人: 联系人
联系人邮箱: 联系人邮箱
审核状态: 审核状态
报备状态: 报备状态
机构ID: 机构ID
所属层级: 所属层级
上级机构: 上级机构
联系人电话: 联系人电话
联系电话: 联系电话
状态异常，无法编辑: 状态异常，无法编辑
子机构: 子机构
商户: 商户
终端: 终端
当前已达到商户标识回收规则的商户如下: 当前已达到商户标识回收规则的商户如下
回收: 回收
一键回收: 一键回收
报备中: 报备中
报备成功: 报备成功
报备失败: 报备失败
无需报备: 无需报备
未启用: 未启用
已启用: 已启用
已禁用: 已禁用
已注销: 已注销
基础信息: 基础信息
永久: 永久
支付产品: 支付产品
保存: 保存
提交: 提交
外部商户号: 外部商户号
扣率(%): 扣率(%)
请输入商户标识，具有唯一性: 请输入商户标识，具有唯一性
输入百分比 3%填写3: 输入百分比 3%填写3
提交时间: 提交时间
处理时间: 处理时间
个人: 个人
企业: 企业
个体: 个体
待处理: 待处理
创建时间: 创建时间
更新时间: 更新时间
最近天数: 最近天数
渠道: 渠道
新增终端: 新增终端
新增子商户: 新增子商户
注销: 注销
注销后，该商户将永久停用: 注销后，该商户将永久停用
是否继续操作: 是否继续操作
当前账户金额存在负数金额: 当前账户金额存在负数金额
标签组: 标签组
终端标识：: 终端标识：
共计x笔: 共计{count}笔
已处理x笔: 已处理{count}笔
未处理x笔: 未处理{count}笔
预览图片: 预览图片
交易要素不一致: 交易要素不一致
新增交易流水系统: 新增交易流水系统
核销详情: 核销详情
上传凭证失败！: 上传凭证失败！
登录成功: 登录成功

请填写驳回原因: 请输入驳回原因
审核商户成功！即将返回: 审核商户成功！即将返回
修改机构成功！即将返回: 修改机构成功！即将返回
新增机构成功！即将返回: 新增机构成功！即将返回
新增商户成功！即将返回: 新增商户成功！即将返回
保存子商户成功！即将返回: 保存子商户成功！即将返回
新增子商户成功！即将返回: 新增子商户成功！即将返回
修改商户成功！即将返回: 修改商户成功！即将返回
提交草稿成功！即将返回: 提交草稿成功！即将返回
系统错误，请稍后再试: 系统错误，请稍后再试
请审核完所有项目之后再提交: 请审核完所有项目之后再提交
导出成功: 导出成功
禁用成功！: 禁用成功！
启用成功！: 启用成功！
删除成功！: 删除成功！
不能同时对多个机构进行编辑！: 不能同时对多个机构进行编辑！
不能同时对多个商户进行操作！: 不能同时对多个商户进行操作！
请选择商户: 请选择商户
只能操作下一级机构: 只能操作下一级机构
保存商户成功！即将返回: 保存商户成功！即将返回
请输入正确的商户标识: 请输入正确的商户标识
请填写商户名称与标识: 请填写商户名称与标识
请选择营业执照有效期: 请选择营业执照有效期
存在资料未填写，请先完善: 存在资料未填写，请先完善
请选择业务地区: 请选择业务地区
至少配置一个支付产品: 至少配置一个支付产品
请为选中的产品配置转出渠道: 请为选中的产品配置转出渠道
不能操作其他机构商户: 不能操作其他机构商户
该商户无法禁用: 该商户无法禁用
该商户无法启用: 该商户无法启用
商户禁用成功！: 商户禁用成功！
商户启用成功！: 商户启用成功！
商户注销成功！: 商户注销成功！
添加标签成功！: 添加标签成功！
导出成功！: 导出成功！
无可用标签，请先进行标签配置!: 无可用标签，请先进行标签配置
请不要同时注销多个商户！: 请不要同时注销多个商户！
请选择未被注销的商户!: 请选择未被注销的商户
回收成功: 回收成功
一键回收成功: 一键回收成功
请先选择设备: 请先选择设备
请为选中的产品配置支付渠道: 请为选中的产品配置支付渠道
支付渠道: 支付渠道
流水单边: 流水单边
要素不平: 要素不平
IP: IP
#-------未翻译分割线------
SWIFT编码: SWIFT编码
立即查看: 立即查看
请选择月份: 请选择月份
最近七天: 最近七天
最近十五天: 最近十五天
最近三十天: 最近三十天
修改: 修改
报备通过: 报备通过
审核成功: 审核通过
待提交: 待提交
#----补充----
请输入账号: 请输入账号
请输入正确的验证码: 请输入正确的验证码
是否禁用该用户: 是否禁用该用户
是否启用该用户: 是否启用该用户
模块: 模块
搜索商户名称/商户标识/联系人: 搜索商户名称/商户标识/联系人
此处为结算日期: 此处为结算日期
当周消费金额（: 当周消费金额({currency})
上一周消费金额（: 上一周消费金额({currency})
商户交易订单号: 商户交易订单号
类型: 类型
厂商名称: 厂商名称
型号名称: 型号名称
设备S/N: 设备S/N
流水号: 流水号
请先删除再上传: 请先删除再上传

请选择上级机构名称: 请选择上级机构名称
最长128位: 最长128位
最长64位: 最长64位
请输入正确的邮箱: 请输入正确的邮箱
请输入手机号: 请输入手机号
请输入正确的手机号: 请输入正确的手机号
请选择商户类型: 请选择行业
请输入商户名称: 请输入商户名称
请输入商户标识(字母数字以及-): 请输入商户标识(字母数字以及-)
请输入联系人: 请输入联系人
请输入注册地址: 请输入注册地址
请输入营业执照编号: 请输入营业执照编号
请输入法人姓名: 请输入法人姓名
请输入法人ID: 请输入法人ID
请输入正确的法人ID: 请输入正确的法人ID
请输入结算账户: 请输入结算账户
请输入SWIFT编码: 请输入SWIFT编码
请输入银行编码: 请输入银行编码
请选择收款行名称: 请选择收款行名称
请选择收款行城市: 请选择收款行城市
请输入地址: 请输入地址
请选择结算周期: 请选择结算周期
请选择交易币种: 请选择交易币种
请选择交易时区: 请选择交易时区
请选择机构日切时间: 请选择机构日切时间
请选择支付产品: 请选择支付产品
请选择支付渠道: 请选择支付渠道
请选择合作模式: 请选择合作模式
请选择计费周期: 请选择计费周期
请选择计费开始时间: 请选择计费开始时间
请输入姓名: 请输入姓名
请输入邮箱: 请输入邮箱
请选择机构组织: 请选择机构组织
请选择角色名称: 请选择角色名称
请选择数据权限: 请选择数据权限

商户交易订单号/商户标识/商户名称/终端标识/SN: 商户交易订单号/商户标识/商户名称/终端标识/SN
所有正向消费的总金额: 所有正向消费的总金额
所有正向消费的总笔数: 所有正向消费的总笔数
=消费金额/消费笔数: =消费金额/消费笔数
所有负向退货的总金额: 所有负向退货的总金额
所有负向退货的总笔数: 所有负向退货的总笔数
不能操作其他机构终端!: 不能操作其他机构终端!

是否重置密钥: 是否重置密钥
请选择终端: 请选择终端
部分退款: 部分退款
支付中: 支付中
拒绝: 拒绝
已撤销: 已撤销
已冲正: 已冲正
已退款: 已退款
交易管理: 交易管理
账务管理: 账务管理

草稿: 草稿
导出机构数据: 导出机构数据
仅能创建下一级机构，不允许跨级创建: 仅能创建下一级机构，不允许跨级创建
是否启用该机构: 是否启用该机构
是否禁用该机构: 是否禁用该机构
是否删除该机构: 是否删除该机构
多选: 多选
是否导出: 是否导出
商户状态: 商户状态
撤销: 撤销
法人姓名: 法人姓名
法人ID: 法人ID
请输入SP ID: 请输入SP ID
请输入0-100: 请输入0-100
请输入商户标识: 请输入商户标识
输入百分比: 输入百分比
餐饮: 餐饮
零售: 零售
总交易笔数: 总交易笔数
总交易金额: 总交易金额
交易金额占比: 交易金额占比
未对平金额: 未对平金额
未对平笔数: 未对平笔数
去处理: 去处理
当前账户存在未对平的账务数据，请先处理: 当前账户存在未对平的账务数据，请先处理
当前账户金额存在正数金额: 当前账户金额存在正数金额
请联系后台处理: 请联系后台处理
是否禁用该商户: 是否禁用该商户
是否启用该商户: 是否启用该商户
#----我是一条美丽的分割线-----
机构管理: 机构管理
列表查询: 列表查询
详情: 详情
批量导入: 批量导入
查询所有商户: 查询所有商户
更改状态: 更改状态
审核管理: 审核管理
审核: 审核
审核页面: 审核页面
终端管理: 终端管理
设备登记: 设备登记
出库: 出库
下载: 下载
渠道管理: 渠道管理
标签管理: 标签管理
删除标签组: 删除标签组
删除标签: 删除标签
交易流水管理: 交易流水管理
交易日志管理: 交易日志管理
操作日志管理: 操作日志管理
系统设置: 系统设置
用户管理: 用户管理
角色管理: 角色管理
经营报告: 经营报告
排行榜: 排行榜
审核详情: 审核详情
对账管理: 对账管理
查看对账结果: 查看对账结果
导入文件: 导入文件
重新对账: 重新对账
对账文件: 对账文件
查询流水文件列表: 查询流水文件列表
查询对账结果: 查询对账结果
对账数据: 对账数据
查询对账数据: 查询对账数据
查询差错进度信息: 查询差错进度信息
查询差错明细列表: 查询差错明细列表
查询差错处理日志: 查询差错处理日志
查询差错详情: 查询差错详情
保存差错处理结果: 保存差错处理结果
提交差错处理结果: 提交差错处理结果
服务费管理: 服务费管理
查看交易明细: 查看交易明细
查询差错待审核申请单列表: 查询差错待审核申请单列表
查询差错待审核进度: 查询差错待审核进度
保存差错审核结果: 保存差错审核结果
提交差错审核结果: 提交差错审核结果
请求TPS对指定差错记录调账处理: 请求TPS对指定差错记录调账处理
交易参考号: 交易参考号
处理: 处理
终端标识: 终端标识
未上传: 未上传
上传中: 上传中
已上传: 已上传
关闭: 关闭
文件名称应为：: 文件名称应为：
通道文件名称: 通道文件名称
已调账: 已调账
审核中: 审核中
导入最后消息: 导入最后消息
不通过: 不通过
已对账: 已对账
重新获取流水: 重新获取流水
调账完成: 调账完成
对账失败，请检查相关数据: 对账失败，请检查相关数据
付款账号/卡号: 付款账号/卡号
交易流水: 交易流水
服务费币种: 服务费币种
请不要同时操作多个用户: 请不要同时操作多个用户
请选择用户: 请选择用户
用户已禁用: 用户已禁用
用户删除成功: 用户删除成功
角色解绑成功: 角色解绑成功
修改用户角色成功！即将返回: 修改用户角色成功！即将返回
修改用户成功！即将返回: 修改用户成功！即将返回
操作员: 操作员
自定义角色: 自定义角色
# 所属组织机构: 所属组织机构
最长32位: 最长32位
确认删除吗: 确认删除吗
数量: 数量
旧密码: 旧密码
新密码: 新密码
再次确认新密码: 再次确认新密码
请输入旧密码: 请输入旧密码
请输入新密码: 请输入新密码
搜索设备SN: 搜索设备SN

单个设备出库: 单个设备出库
批量设备出库: 批量设备出库
单个设备入库: 单个设备入库
批量设备入库: 批量设备入库
解绑用户: 解绑用户
对账文件导入: 对账文件导入
调账: 调账
修改用户密码: 修改用户密码
商户打标签: 商户打标签
组织架构管理: 组织架构管理
对账模块: 对账模块
差错模块: 差错模块
标签模块: 标签模块
商户模块: 商户模块
设备管理: 设备管理
文件管理: 文件管理
新密码不能与旧密码相同: 新密码不能与旧密码相同
排名: 排名
机构名: 机构名
较上天: 较上天
商户名: 商户名
操作成功: 操作成功
设备批量导入结果: 设备批量导入结果
共计导入数据: 共计导入数据
成功导入: 成功导入
导入失败: 导入失败

设备批量入库结果: 设备批量入库结果
设备批量出库结果: 设备批量出库结果
入库成功: 入库成功
出库成功: 出库成功
入库失败: 入库失败
出库失败: 出库失败
失败数据下载: 失败数据下载
当周消费笔数: 当周消费笔数
上一周消费笔数: 上一周消费笔数
当周消费单均价: 当周消费单均价
上一周消费单均价: 上一周消费单均价
第某周: 第{week}周
反交易: 反交易
联迪: 联迪
百富: 百富
新国都: 新国都
L180机型: L180机型
G180机型: G180机型
八达通支付: 八达通支付
八达通退款: 八达通退款
八达通撤销: 八达通撤销
八达通查询: 八达通查询
更新密钥: 更新密钥
交换数据: 交换数据
八达通: 八达通
中国: 中国
德国: 德国
中国香港: 中国香港
哈萨克斯坦: 哈萨克斯坦
美国: 美国
北京: 北京
成都: 成都
重庆: 重庆
大连: 大连
广州: 广州
哈尔滨: 哈尔滨
海南: 海南
呼和浩特: 呼和浩特
昆明: 昆明
上海: 上海
三亚: 三亚
深圳: 深圳
浙江: 浙江
慕尼黑: 慕尼黑
香港: 香港
努尔苏丹: 努尔苏丹
华盛顿: 华盛顿
纽约: 纽约
旧金山: 旧金山
消费: 消费
退款: 退款
在库: 在库
在用: 在用
用户权限不足: 用户权限不足
当前机构不允许启用: 当前机构不允许启用
当前商户状态不为草稿状态: 当前商户状态不为草稿状态
草稿状态无法注销: 草稿状态无法注销
当前已经是注销状态: 当前已经是注销状态
在用设备不能出库: 在用设备不能出库
出库状态不允许修改: 出库状态不允许修改
在用状态不允许修改: 在用状态不允许修改
请填写终端支付参数: 请填写终端支付参数
终端支付参数与商户不匹配: 终端支付参数与商户不匹配
请填写完整终端支付参数: 请填写完整终端支付参数
该商户回填参数已经录入: 该商户回填参数已经录入
当前绑定设备已经出库: 当前绑定设备已经出库
当前绑定设备已经在用: 当前绑定设备已经在用
当前机构已被禁用: 当前机构已被禁用
当前机构已被删除: 当前机构已被删除
证件号码超长: 证件号码超长
推广人超长: 推广人超长
邮箱超长: 邮箱超长
联系人超长: 联系人超长
商户标识超长: 商户标识超长
初审失败原因长度超长: 初审失败原因长度超长
设备类型长度超长: 设备类型长度超长
厂商code 例如：PAX: 厂商code 例如：PAX
厂商名: 厂商名
机型code 例如：A920: 机型code 例如：A920
机型名: 机型名
设备类型（1-pos终端）: 设备类型（1-pos终端）
设备序列号sn: 设备序列号sn
出库原因（出库须填）: 出库原因（出库须填）
导入失败原因: 导入失败原因
请填写完整的设备信息: 请填写完整的设备信息
请填写正确的设备类型: 请填写正确的设备类型
出库设备不存在: 出库设备不存在
出库设备在用: 出库设备在用
出库设备已出库: 出库设备已出库
其他原因: 其他原因
设备sn重复: 设备sn重复
厂商和机型不匹配: 厂商和机型不匹配
设备类型有误: 设备类型有误
#---清结算后端词条---
请求成功: 请求成功
系统错误，请重试: 系统错误，请重试
登录超时,请重新登录: 登录超时,请重新登录
未授权，拒绝访问: 未授权，拒绝访问
业务处理异常: 业务处理异常
非法参数: 非法参数
非法的请求方式: 非法的请求方式
文件为空，请选择文件: 文件为空，请选择文件
上传文件成功: 上传文件成功
上传失败，请选择文件: 上传失败，请选择文件
未知错误: 未知错误
下载文件失败: 下载文件失败
上传文件失败: 上传文件失败
机构ID为空: 机构ID为空
商户号为空: 商户号为空
未找到对账批次信息: 未找到对账批次信息
未找到差错进度信息: 未找到差错进度信息
未找到差错记录: 未找到差错记录
不允许进行差错处理: 不允许进行差错处理
未知的差错处理方法: 未知的差错处理方法
存在相同订单号的网关流水: 存在相同订单号的网关流水
新增网关流水失败: 新增网关流水失败
修改网关流水失败: 修改网关流水失败
保存差错处理结果失败: 保存差错处理结果失败
请先处理完所有的差错: 请先处理完所有的差错
无差错记录需要审核: 无差错记录需要审核
共提交%d条差错处理结果, 其中: 共提交{count}条差错处理结果, 其中
差错待审核单号不能为空: 差错待审核单号不能为空
未找到指定差错待审核单: 未找到指定差错待审核单
该记录不允许进行审核操作: 该记录不允许进行审核操作
保存差错审核结果失败: 保存差错审核结果失败
无差错审核记录可以提交: 无差错审核记录可以提交
请先处理完所有差错记录: 请先处理完所有差错记录
请先审核完所有差错处理结果: 请先审核完所有差错处理结果
所有差错记录都已调账完成: 所有差错记录都已调账完成
有%d条差错记录调账失败！: 有{count}条差错记录调账失败！
通知TPS程序生成本地流水文件失败！: 通知TPS程序生成本地流水文件失败！
TPS重新生成流水文件成功: TPS重新生成流水文件成功
无效帐单ID: 无效帐单ID

暂未完成对账，无法输出数据，请耐心等待！: 暂未完成对账，无法输出数据，请耐心等待！
请填写渠道终端标识参数: 请填写渠道终端标识参数
部分终端已禁用: 部分终端已禁用
部分终端已启用: 部分终端已启用
账户中心: 用户中心
确认解绑吗: 确认解绑吗
运营平台: 运营平台
全选: 全选
请先选择一种处理方式: 请先选择一种处理方式
#--------我是一条分割线-------
重新对账完成: 重新对账完成
系统初始化失败: 系统初始化失败
系统预处理失败: 系统预处理失败
连接STTL后台服务失败，请检查STTL后台服务: 连接STTL后台服务失败，请检查STTL后台服务
系统繁忙，请稍后再操作: 系统繁忙，请稍后再操作
汇总失败，请检查相关数据: 汇总失败，请检查相关数据
请求TPS重新上传流水文件: 请求TPS重新上传流水文件
查询商户列表: 查询商户列表
修改机构回收MID天数: 修改机构回收PMR天数
批量插入机构: 批量插入机构
修改机构: 修改机构
新增机构: 新增机构
禁用机构: 禁用机构
删除机构: 删除机构
启用机构: 启用机构
修改商户: 修改商户
审核商户: 审核商户
启用、禁用、注销商户: 启用、禁用、注销商户
设备批量入库: 设备批量入库
设备批量出库: 设备批量出库
设备信息修改: 设备信息修改
设备删除: 设备删除
启用、禁用、注销、删除终端: 启用、禁用、注销、删除终端
修改终端: 修改终端
创建用户: 创建用户
修改用户: 修改用户
启用用户: 启用用户
冻结用户: 冻结用户
修改密码: 修改密码
删除用户: 删除用户
创建角色: 创建角色
删除角色: 删除角色
修改角色: 修改角色
机构业务开展国家不包含该国家: 机构业务开展国家不包含该国家
上级机构不存在: 上级机构不存在
不能跨级创建机构: 不能跨级创建机构
六级机构不能添加子机构: 六级机构不能添加子机构
机构不存在: 机构不存在
当前用户所属机构状态异常: 当前用户所属机构状态异常
当前机构不是0级机构: 当前机构不是0级机构
该机构当前不处于停用状态: 该机构当前不处于停用状态
在停用180天后方可删除，当前还剩: 在停用180天后方可删除，当前还剩
当前机构不处于禁用状态: 当前机构不处于禁用状态
所选机构不存在: 所选机构不存在
子商户标识不能和父商户重复: 子商户标识不能和父商户重复
商户标识已经存在: 商户标识已经存在
非0级商户商户不能添加子商户: 非0级商户商户不能添加子商户
当前商户状态异常不能添加子商户: 当前商户状态异常不能添加子商户
所选机构缺少接入通道配置，请联系管理员: 所选机构缺少接入通道配置，请联系管理员
所选商户不存在: 所选商户不存在
当前用户信息缺失: 当前用户信息缺失
当前机构不能查看该商户: 当前机构不能查看该商户
当前待审核状态不能修改: 当前待审核状态不能修改
当前报备中状态不能修改: 当前报备中状态不能修改
所选机构信息不完整，请联系管理员: 所选机构信息不完整，请联系管理员
当前登录信息已过期: 当前登录信息已过期
当前商户信息操作权限受限: 当前商户信息操作权限受限
商户没有待处理的记录: 商户没有待处理的记录
商户待审核记录已处理: 商户待审核记录已处理
注销状态不能进行其他操作: 注销状态不能进行其他操作
当前商户状态不允许注销: 当前商户状态不允许注销
该sn已经存在: 该sn已经存在
出库设备信息错误: 出库设备信息错误
该设备已经被使用，暂不能删除: 该设备已经被使用，暂不能删除
设备不存在: 设备不存在
下载模板失败，请联系管理员: 下载模板失败，请联系管理员
修改设备不存在: 修改设备不存在
机构信息未录入: 机构信息未录入
修改信息失败: 修改信息失败
文件类型错误: 文件类型错误
数据不能为空: 数据不能为空
解析excel文件出错: 解析excel文件出错
长度超长: 长度超长
厂商与机型不匹配: 厂商与机型不匹配
终端信息不存在: 终端信息不存在
终端与机构关系错误: 终端与机构关系错误
商户与机构关系错误: 商户与机构关系错误
该终端标识已经存在: 该终端标识已经存在
该设备已被绑定: 该设备已被绑定
当前设备不属于该机构: 当前设备不属于该机构
当前设备已经出库: 当前设备已经出库
请录入设备信息: 请录入设备信息
终端修改状态错误: 终端修改状态错误
当前机构不能对该终端进行修改: 当前机构不能对该终端进行修改
终端状态异常，暂不能重置密钥: 终端状态异常，暂不能重置密钥
当前机构不能对该终端进行操作: 当前机构不能对该终端进行操作
没有操作权限: 没有操作权限
标签名已被使用!: 标签名已被使用!
商户业务开展地区错误: 商户业务开展地区错误
0级机构不存在: 0级机构不存在
商户当前状态不能参与审核: 商户当前状态不能参与审核
当前商户状态不允许启用: 当前商户状态不允许启用
商户只允许禁用状态下才能注销: 商户只允许禁用状态下才能注销
商户信息不存在: 商户信息不存在
权限不足: 权限不足
该商户账务异常，无法注销: 该商户账务异常，无法注销
请先删除该商户下的终端资源: 请先删除该商户下的终端资源
用户名或密码错误: 用户名或密码错误
用户已锁定: 用户已锁定
用户名不存在: 用户名不存在
标签组名已被使用!: 标签组名已被使用!
标签组不存在: 标签组不存在
设备机构信息未录入: 设备机构信息未录入
当前设备信息与用户不匹配: 当前设备信息与用户不匹配
不能对当前机构进行删除: 不能对当前机构进行删除
该机构不能删除: 该机构不能删除
不能对当前机构进行禁用: 不能对当前机构进行禁用
状态为启用的机构才可以禁用: 状态为启用的机构才可以禁用
没有对该机构的操作权限: 没有对该机构的操作权限
该商户已经是禁用状态了: 该商户已经是禁用状态了
该商户已经是注销状态了: 该商户已经是注销状态了
该商户不能删除: 该商户不能删除
邮件发送失败: 邮件发送失败
系统错误: 系统错误
渠道不存在: 渠道不存在
渠道已被禁用: 渠道已被禁用
请先解绑同用户的关联关系!: 请先解绑同用户的关联关系!
机构未选择: 机构未选择
用户名已存在: 用户名已存在
用户邮箱已存在: 用户邮箱已存在
用户已被禁用: 用户已被禁用
错误的用户名或者密码: 错误的用户名或者密码
旧密码错误: 旧密码错误
密码错误: 密码错误
密码错误!: 密码错误!

当月排名: 当月排名
较上一月排名变化: 较上一月排名变化
当周排名: 当周排名
较上一周排名变化: 较上一周排名变化
较去年同月排名变化: 较去年同月排名变化
绑定角色成功！: 绑定角色成功！
删除角色成功！: 删除角色成功！
修改密码成功: 修改密码成功
是否确认删除角色？: 是否确认删除角色？
手动入库: 手动入库
手动出库: 手动出库
报损出库: 报损出库
其他出库: 其他出库
批量处理失败明细: 批量处理失败明细
设备入库出库模板: 设备入库出库模板
请再次输入新密码: 请再次输入新密码
两次输入不一致: 两次输入不一致
渠道终端标识参数: 渠道终端标识参数
渠道商户标识参数: 渠道商户标识参数
请输入名称: 请输入名称
请输入描述: 请输入描述
添加角色成功！即将返回: 添加角色成功！即将返回
修改角色成功！即将返回: 修改角色成功！即将返回
支付对账: 支付对账
平台: 平台
添加用户成功！即将返回: 添加用户成功！即将返回
所属机构组织: 所属机构组织
平台超级管理员-初始: 平台超级管理员-初始
平台管理员-初始: 平台管理员-初始
平台操作员-初始: 平台操作员-初始
机构超级管理员(非0级)-初始: 机构超级管理员(非0级)-初始
机构管理员(非0级)-初始: 机构管理员(非0级)-初始
机构操作员(非0级)-初始: 机构操作员(非0级)-初始
机构审核员(非0级)-初始: 机构审核员(非0级)-初始
机构超级管理员(0级)-初始: 机构超级管理员(0级)-初始
机构管理员(0级)-初始: 机构管理员(0级)-初始
机构操作员(0级)-初始: 机构操作员(0级)-初始
机构审核员(0级)-初始: 机构审核员(0级)-初始
备注: 备注
交易要素不平: 交易要素不平
请重新登录！: 请重新登录
请重新登录!: 请重新登录

笔数: 笔数
金额: 金额
上级商户被禁用不允许启用: 上级商户被禁用不允许启用
子商户启用失败，因所属商户未启用: 子商户启用失败，因所属商户未启用
当前机构不能操作该商户: 当前机构不能操作该商户
登录模块: 登录模块
请不要同时操作多个设备！: 请不要同时操作多个设备！
商户业务逻辑id不能为空: 商户业务逻辑id不能为空
请选择非注销、草稿状态的商户: 请选择非注销、草稿状态的商户
当前用户没有权限对该机构进行修改: 当前用户没有权限对该机构进行修改
请选择未出库设备: 请选择未出库设备
请选择在库设备: 请选择在库设备
打标签: 打标签

确认解冻吗: 确认解冻吗？
确认冻结吗: 确认冻结吗？
冻结: 冻结
解冻: 解冻

确认重置密码吗: 确认重置密码吗？
长期: 长期
6-20位: 6-20位
商户超级管理员: 商户超级管理员
门店超级管理员: 门店超级管理员
重置密码: 重置密码
创建中: 创建中
创建失败: 创建失败
手机: 手机
商户添加账号: 商户添加账号
商户不存在: 商户不存在
门店不存在: 门店不存在
对账未完成: 对账未完成
保存密码: 保存密码
请妥善保管您的密码: 请妥善保管您的密码
请输入正确的电话号码: 请输入正确的电话号码
手机号码已存在: 手机号码已存在
手机号已存在: 手机号已存在
邮箱已存在: 邮箱已存在
#---补充分割线----
经营地址: 经营地址
负责人名称: 负责人名称
负责人ID: 负责人ID
门店名称: 门店名称
APP下载链接: APP下载链接
业务网站: 业务网站
公众号: 公众号
小程序: 小程序
商户类目: 商户类型
门头照: 门头照
建议尺寸: 建议尺寸
限制大小: 限制大小
店内照: 店内照
收银台照: 收银台照
图片格式错误!: 图片格式错误!
图片大小不能超过2M!: 图片大小不能超过2M!
请输入: 请输入
请完善照片: 请完善照片
微信线下: 微信线下
微信线上: 微信线上
支付宝线上: 支付宝线上
支付宝线下: 支付宝线下
公司电话: 公司电话
行业类目: 行业类目
报备详情: 报备详情
VISA分期: VISA分期
所属地区: 所属地区
其他参数: 其他参数
请选择: 请选择
当前设备已存在: 当前设备已存在
是: 是
否: 否
商 户: 商 户
终 端: 终 端

# visa
Export file generating, please wait: 导出文件生成中，请稍候
Estimated completion time: 预计完成时间
You can make the exporting: 您可以进行导出
Process at background: 后台运行
Cancel the exporting: 取消导出
or: 或者
After the file is generated, it will download automatically: 文件生成后，会自动下载
是否导出机构数据: 是否导出机构数据？
是否导出商户数据: 是否导出商户数据？
是否导出终端数据: 是否导出终端数据?
导入: 导入

请稍等，正在导出: 请稍等，正在导出
商户导出: 商户导出
终端导出: 终端导出
商户导入结果: 商户导入结果
终端导入结果: 终端导入结果
是否停用该商户: 是否禁用该商户？
停用: 禁用

请选择所属地区: 请选择所属地区
文件名称: 文件名称
下载失败记录数: 下载失败记录数
是否下载失败记录数: 是否下载失败记录数
是否下载文件: 是否下载文件
文件生成完毕: 文件生成完毕
待报备: 待报备
正在计算: 正在计算
只支持扩展名为xls、xlsx的文件: 只支持扩展名为xls、xlsx的文件
一次只能上传一个文件: 一次只能上传一个文件
启用商户: 启用
商户停用成功！: 商户禁用成功！

导入时间: 导入时间
导入状态: 导入状态
导入类型: 导入类型
总记录数: 总记录数
成功记录数: 成功记录数
失败记录数: 失败记录数
下载失败记录: 下载失败记录
批量导入终端: 批量导入终端
批量导入商户: 批量导入商户
是否下载失败记录: 是否下载失败记录
导出时间: 导出时间
文件状态: 文件状态
成功: 成功
生成成功: 生成成功
生成失败: 生成失败

确认分期计划: 确认分期计划
分期状态: 分期状态
分期计划ID: 分期计划ID
确认分期ID: 确认分期ID
还款周期: 还款周期
分期总额: 分期总额
分期总手续: 分期总手续
首期手续费: 首期手续费
末期本金: 末期本金
末期手续费: 末期手续费
分期期数: 分期期数
首期本金: 首期本金
分期总手续费: 分期总手续费
支付宝线下进件: 支付宝线下进件
支付宝线上进件: 支付宝线上进件
VISA进件: VISA进件
微信进件: 微信进件
分期退款: 分期退款
终端批量导入: 终端批量导入
终端批量导出: 终端批量导出
商户批量导入: 商户批量导入
商户批量导出: 商户批量导出
不能包含空格等空白符号: 不能包含空格等空白符号
配置参数: 配置参数
请输入登录密码: 请输入登录密码
Outbound because of damage: 报损出库

# 产品改名
Octopus: 八达通
Visa Installment: VISA分期
WeChat Offline: 微信线下
WeChat Online: 微信线上
Alipay Offline: 支付宝线下
Alipay Online: 支付宝线上
# 后端采集
批量入库: 批量入库
Merchant不能为空: Merchant不能为空
TID不能为空: TID不能为空
S/N不能为空: S/N不能为空
Vendor不能为空: Vendor不能为空
Model不能为空: Model不能为空
Merchant格式错误: Merchant格式错误
终端重复:TID: 终端重复:TID
终端重复：S/N Vendor Model: 终端重复：S/N Vendor Model
必填参数不能为空: 必填参数不能为空
Merchant不存在,或者商户当前状态不支持新增终端,或者不支持其子机构下的商户导入: Merchant不存在,或者商户当前状态不支持新增终端,或者不支持其子机构下的商户导入
不满足其它约束问题: 不满足其它约束问题
Merchant Name不能为空: Merchant Name不能为空
Merchant ID不能为空: Merchant ID不能为空
Contact不能为空: Contact不能为空
Contact Email不能为空: Contact Email不能为空
Address不能为空: Address不能为空
Country不能为空: Country不能为空
Time Zone不能为空: Time Zone不能为空
Phone不能为空: Phone不能为空
Product不能为空: Product不能为空
Channel不能为空: Channel不能为空
Country不存在: Country不存在
Time Zone不存在: Time Zone不存在
Product不存在: Product不存在
Channel不存在: Channel不存在
同一0级机构下的机构及其子机构间不能出现重复的商户号Merchant ID: 同一0级机构下的机构及其子机构间不能出现重复的商户号Merchant ID
提示: 提示
请输入6到13位正确的手机号码: 请输入6到13位正确的手机号码
邮箱格式不正确: 邮箱格式不正确
格式不正确: 格式不正确
商户模板下载: 商户模板下载
批量导出商户: 批量导出商户
商户导出失败记录: 商户导出失败记录
批量导出终端: 批量导出终端
终端导出失败记录: 终端导出失败记录
终端模板下载: 终端模板下载
超时: 超时

至少选择一项: 至少选择一项
Sub Merchant ID: 子商户号
正在努力加载中...: 正在努力加载中...

异常: 异常
重置成功: 重置成功
是否重置该用户密码: 是否重置该用户密码
复制: 复制
7位以上数字和大小写字母: 7位以上数字和大小写字母
已复制到剪切板: 已复制到剪切板
密码输入错误次数达到5次，用户已被锁定，请联系管理员解锁: 密码输入错误次数达到5次，用户已被锁定，请联系管理员解锁
用户不存在或者状态异常: 用户不存在或者状态异常
密码不能和前四次中的任何一次相同: 密码不能和前四次中的任何一次相同
密码包含了不支持的字符: 密码包含了不支持的字符
密码长度不能小于7: 密码长度不能小于7
密码长度不能大于32: 密码长度不能大于32
重置用户密码: 重置用户密码
密码已超过90天未修改: 密码已超过90天未修改
账号首次登录，需要修改密码: 账号首次登录，需要修改密码
至少七位，必须含字母和数字: 至少七位，必须含字母和数字
签名状态: 签名状态
不能重置本用户的密码: 不能重置本用户的密码
当前渠道密钥保存方式未知: 当前渠道密钥保存方式未知
修改渠道成功！即将返回: 修改渠道成功！即将返回
搜索渠道名称/渠道ID: 搜索渠道名称/渠道ID
未配置: 未配置
配置状态: 配置状态
配置完成: 配置完成
无法删除当前用户: 无法删除当前用户
重新配置: 重新配置
最长4096位: 最长4096位
商户标识-标题: 商户标识

商户的唯一标识: 商户的唯一标识

原商户订单号/商户标识/商户名称/终端标识/SN: 原商户订单号/商户标识/商户名称/终端标识/SN
原商户订单号/商户标识/商户名称/终端标识: 原商户订单号/商户标识/商户名称/终端标识
退货: 退款
原商户交易订单号: 原商户交易订单号
渠道处理中: 渠道处理中
系统处理中: 系统处理中
重发: 重发
退货失败: 退款失败
退货成功: 退款成功
退货金额: 退款金额
可退货金额: 可退款金额
请输入正数: 请输入正数
请输入正确格式: 请输入正确格式
输入金额不能大于可退货金额: 输入金额不能大于可退货金额
最多两位小数: 最多两位小数
请输入退货金额: 请输入退货金额
是否重新发起退货: 是否重新发起退货
退货申请提交成功: 退款申请提交成功
退货申请提交失败，请重试: 退款申请提交失败，请重试
退货记录: 退款记录
退货审核: 退款审核
退款金额: 退款金额
退款状态: 退款状态
审核失败: 审核失败
退款失败: 退款失败
退款成功: 退款成功
是否发起查询: 是否发起查询
重新发起退货: 重新发起退货
审核通过-按钮: 审核通过
审核驳回-按钮: 审核驳回
审核通过-标题: 审核通过
审核驳回-标题: 审核驳回
驳回: 驳回
生成中: 生成中
vPlanRef: Plan Ref
externalTransactionRef: 分期MID
vPlanID: Plan ID
vPlanAcceptanceId: PlanAcceptance ID
退款仅支持180天之内的交易: 退款仅支持180天之内的交易
退款金额不能超过原交易金额: 退款金额不能超过原交易金额
当前退款结果未知，请先查询结果: 当前退款结果未知，请先查询结果
当前退款状态已变化，请稍候再试: 当前退款状态已变化，请稍候再试
退款模块: 退款模块
提交退款: 提交退款
重新提交退款: 重新提交退款
退款审核: 退款审核

渠道配置: 渠道配置
渠道列表: 渠道列表
修改渠道参数: 修改渠道参数
退款管理: 退款管理
退款列表: 退款列表
查询退款结果: 查询退款结果
重新发起退款申请: 重新发起退款申请
退款审核管理: 退款审核管理
获取退款订单列表: 获取退款订单列表
响应超时，请稍后再试: 响应超时，请稍后再试
获取时区失败: 获取时区失败
获取邮箱失败: 获取邮箱失败

无法获取: 无法获取
发送成功: 发送成功
重置双因子认证: 重置双因子认证
verifyTip1: 请用Google Authenticator扫描下方二维码添加KlondPay系统进行双因子验证
verifyTip2: 请在Google Authenticator中查看动态验证码
verifyTip3: 动态验证码有效期30S
verifyTip4: 请使用与KlondPay绑定的Google Authenticator账号
verifyTip5: 您可以使用邮箱重置KlondPay系统的双因子验证，重置成功后，您需重新使用Google Authenticator扫描二维码添加KlondPay系统进行双因子验证，请谨慎操作。

验证码已经失效: 验证码已经失效
code不正确: 动态验证码验证不通过
请勿在60S内重复获取验证码: 请勿在60S内重复获取验证码
code验证不通过: code验证不通过

当前账号: 当前账号
动态验证码: 动态验证码
重新发送: 重新发送
发送验证码: 发送验证码
请重新登录完成双因子验证配置: 请重新登录完成双因子验证配置
动态验证码验证不通过: 动态验证码验证不通过

获取绑定认证二维码链接: 获取绑定认证二维码链接
检查认证因子CODE是否正确: 检查认证因子CODE是否正确
重置谷歌密钥: 重置谷歌密钥
检查邮箱验证码是否正确: 检查邮箱验证码是否正确
outtransactionno: 流水号
商户交易订单号/商户标识/商户名称/终端标识/SN/流水号/分期MID: 商户交易订单号/商户标识/商户名称/终端标识/SN/流水号/分期MID
商户交易订单号/商户标识/商户名称/终端标识/SN/流水号: 商户交易订单号/商户标识/商户名称/终端标识/SN/流水号

汇率转换: 汇率转换
默认0: 默认0
转换币种: 转换币种
汇率: 汇率
转换金额: 转换金额
设置转换汇率: 设置转换汇率
无需配置: 无需配置
最长40位: 最长40位
请保存Key: 请保存Key
Key is empty: Key为空

商户分组: 商户分组
请选择商户分组: 请选择商户分组
默认分组: 默认分组
最少6位: 最少6位
是否导出流水数据: 是否导出流水数据
生成中，请在文件管理查看文件: 生成中，请在文件管理查看文件

清除全部: 清除全部
清除: 清除
是否清除全部记录: 是否清除全部记录

交易方式: 交易方式
交易单号: 交易单号
终端号(TID): 终端标识(TID)
商户号(PMR): 商户标识(PMR)
结算金额: 结算金额
商户费率: 商户费率
交易手续费: 交易手续费
结算净额: 结算净额
结算管理: 结算管理
结算明细: 结算明细
结算汇总: 结算汇总
是否导出数据: 是否导出数据?

查询明细列表: 查询明细列表
查询明细导出: 查询明细导出
查询明细币种列表: 查询明细币种列表
查询结算汇总列表: 查询结算汇总列表
查询结算汇总币种列表: 查询结算汇总币种列表
查询结算汇总导出: 查询结算汇总导出
当前已有退货申请在处理中: 当前已有退货申请在处理中
密码不能与前 14 次相同: 密码不能与前 14 次相同

共: 共
条分页: 条
支付金额: 支付金额
资金管理: 资金管理
资金结算报表: 资金结算报表
付款批次: 付款批次
已结算金额: 已结算金额
结算币种: 结算币种
支付净额: 支付净额
手续费金额: 手续费金额
资金结算报表数据，以北京东8区时间为基准统计: 资金结算报表数据，以北京东8区时间为基准统计
交易开始日期: 交易开始日期
交易结束日期: 交易结束日期
错误的日期: 错误的日期
是否导出报表: 是否导出报表
导出流水: 导出流水
删除导出历史: 删除导出历史

FTP配置: FTP配置
通知配置: 通知配置
是否删除: 是否删除
文件配置信息已存在: 文件配置信息已存在
文件配置不存在: 文件配置不存在
修改配置信息失败: 修改配置信息失败

文件自动化: 文件自动化
文件配置: 文件配置
过程追踪: 过程追踪
文件类型: 文件类型
文件格式: 文件格式
（用于配置客户的FTP通讯地址）: （用于配置客户的FTP通讯地址）
端口号: 端口号
访问配置: 访问配置
访问路径: 访问路径
密码类型: 密码类型
登录密码(字母、数字、特殊字符组合）: 登录密码(字母、数字、特殊字符组合）
自定义，不可重名: 自定义，不可重名
不包含汉字、空格以及换行符: 不包含汉字、空格以及换行符
不支持的密码格式: 不支持的密码格式
通信配置: 通信配置
通知开关: 通知开关
通知方式: 通知方式
邮件通知: 邮件通知
通知时间: 通知时间
（系统将在文件生成并传输成功后，自动通知至目标邮箱）: （系统将在文件生成成功成功后，自动通知至目标邮箱）
收件人: 收件人
抄送人: 抄送人
已选择: 已选择
内容配置: 内容配置
数据过滤: 数据过滤
包含的商户组: 包含的商户组
生成频率：每日: 生成频率：每日
生成状态: 生成状态
FTP传输状态: FTP传输状态
邮件发送状态: 邮件发送状态
重新生成: 重新生成
重新传输: 重新传输
是否重新生成: 是否重新生成
是否重新传输: 是否重新传输
是否重新发送: 是否重新发送
未生成: 未生成
传输中: 传输中
未发送: 未发送
发送中: 发送中
名称重复: 名称重复
操作成功！即将返回: 操作成功！即将返回
请选择收件人: 请选择收件人
仅数字: 仅数字
仅数字和.: 仅数字和.
重新生成报表: 重新生成报表
重新发送FTP: 重新发送FTP
重新发送邮件: 重新发送邮件
请先配置邮件信息: 请先配置邮件信息
请先配置FTP信息: 请先配置FTP信息
格式错误: 格式错误
配置不存在，不允许重新生成: 配置不存在，不允许重新生成
创建日期: 创建日期
文件追踪: 文件追踪
退款金额-结算: 退款金额
开始时间: 开始时间
结束时间: 结束时间

报表模块: 报表模块
关闭FTP配置: 关闭FTP配置
重新发送邮件-操作: 重新发送邮件
重新上传FTP: 重新上传FTP
导出结算明细: 导出结算明细
导出结算汇总: 导出结算汇总
导出资金结算: 导出资金结算
实时: 实时
流水模块: 流水模块

# 编辑文件参数: 编辑文件参数
# 配置FTP参数: 配置FTP参数
# 配置通知参数: 配置FTP参数
# 修改FTP参数: 修改FTP参数
# 修改通知参数: 修改通知参数
# 停用FTP参数: 停用FTP参数
# 停用通知参数: 停用通知参数
导入与导出: 导入与导出
请先启用邮件信息: 请先启用邮件信息
请先启用FTP配置: 请先启用FTP配置
报表还未生成成功: 报表还未生成成功
当前报表已在生成中，请稍候: 当前报表已在生成中，请稍候
sftp上传失败: sftp上传失败
威富通: 威富通
本地流水对账: 本地流水对账
批次号: 批次号
交易流水详情: 交易流水详情
交易日志详情: 交易日志详情
退货记录详情: 退货记录详情
退款记录详情: 退款记录详情
退款详情: 退款详情
原交易详情: 原交易详情

微信: 微信
支付宝: 支付宝
银联: 银联
数据生成状态: 数据生成状态
数据未生成: 数据未生成
数据已生成: 数据已生成
数据已生成表示当天所有渠道的数据已进入结算环节，不可再修改；: 数据已生成表示当天所有渠道的数据已进入结算环节，不可再修改；
若数据未生成，则表示尚有未进入结算环节的渠道数据，此渠道数据仍可修改。: 若数据未生成，则表示尚有未进入结算环节的渠道数据，此渠道数据仍可修改。
数据已生成表示当天所有渠道的数据已进入结算环节，不可再修改；若数据未生成，则表示尚有未进入结算环节的渠道数据，此渠道数据仍可修改。: 数据已生成表示当天所有渠道的数据已进入结算环节，不可再修改；若数据未生成，则表示尚有未进入结算环节的渠道数据，此渠道数据仍可修改。

款项类型: 款项类型
补录款: 补录款
冲销款: 冲销款
冲销: 冲销
商户注册名称: 商户注册名称
请输入商户注册名称: 请输入商户注册名称
支付时间: 支付时间
请输入营业执照名称: 请输入营业执照名称
营业执照名称: 营业执照名称
按钮关闭: 关闭
登记时间: 登记时间
结算异常: 结算异常
本页面展示对账日期内产生结算异常的交易流水信息，需进行结算异常处理；处理完成后，相关款项将反映在商户的指定结算明细表中。: 本页面展示对账日期内产生结算异常的交易流水信息，需进行结算异常处理；处理完成后，相关款项将反映在商户的指定结算明细表中。
冲销调整: 冲销调整
新增一条与原流水相同的负向款项，用于抵消本地多出款项: 新增一条与原流水相同的负向款项，用于抵消本地多出款项
手续费: 手续费
处理类型: 处理类型
冲销款项明细: 冲销款项明细
结算异常列表: 结算异常列表
结算异常详情: 结算异常详情
结算异常处置: 结算异常处置
缺少对应流水: 缺少对应流水
交易金额不等: 交易金额不等

组织信息: 组织信息

Shift4交易: Shift4交易
